<template>
  <div>
    <component-menu></component-menu>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 mb-2">
          <h2>{{$t("lang.application.image_converter.name")}}</h2>
          <p class="fw-light">{{$t("lang.application.image_converter.detailed_description")}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mb-2" id="create-form-errors" v-if="createForm.errors.length > 0">
          <div class="alert alert-danger">
            <div v-for="error in createForm.errors" :key="error">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 mb-2">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <label for="inputMediaFile" class="col-sm-2 form-label">{{$t("lang.label.source_file")}}</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" id="inputMediaFile">
                </div>
              </div>
              <div class="row mb-2">
                <label for="editMediaFile" class="col-sm-2 form-label">{{$t("lang.label.preview")}}</label>
                <div class="col-sm-10">
                  <img id="defaultMediaFile" src='' style='display: none;'>
                  <img id="previewMediaFile" src='' style='height: 300px;display: none;'>
                  <canvas id="editMediaFile" style='display: none;'></canvas>
                </div>
              </div>
              <div class="row mb-2">
                <label for="outputEffect" class="col-sm-2 form-label">{{$t("lang.label.add_effect")}}</label>
                <div class="col-sm-10">
                  <span v-if="imageLoaded === true">
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="addEffect('default')">{{$t("lang.application.image_converter.label.effect_default")}}</button>&nbsp;
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="addEffect('grayscale')">{{$t("lang.application.image_converter.label.effect_grayscale")}}</button>&nbsp;
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="addEffect('threshold')">{{$t("lang.application.image_converter.label.effect_threshold")}}</button>&nbsp;
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="addEffect('invert')">{{$t("lang.application.image_converter.label.effect_invert")}}</button>&nbsp;
                    <button type="button" class="btn btn-info btn-sm mb-2" @click="addEffect('gamma')">{{$t("lang.application.image_converter.label.effect_gamma")}}</button>&nbsp;
                  </span>
                  <span v-else></span>
                </div>
              </div>
              <div class="row mb-2">
                <label for="outputType" class="col-sm-2 form-label">{{$t("lang.label.destination_format")}}</label>
                <div class="col-sm-10">
                  <span v-if="imageLoaded === true">
                    <select id="outputType" class="form-select" v-model="createForm.type" @change="setDefaultQuality()">
                      <option :value="`${key}`" v-for="(outputImageType, key) in outputImageTypes" :key="key">{{outputImageType.name}}</option>
                    </select>
                  </span>
                  <span v-else></span>
                </div>
              </div>
              <!--
              <div class="row mb-2" v-show="outputImageTypes[createForm.type].use_quality === true">
                <label for="outputQuality" class="col-sm-2 form-label">{{$t("lang.label.quality")}}</label>
                <div class="col-sm-10">
                  <span v-if="imageLoaded === true">
                    <input type="number" id="outputQuality" class="form-control" v-model="createForm.quality" step="0.1" min="0.1" max="1.0">
                  </span>
                  <span v-else></span>
                </div>
              </div>
              -->
              <div class="row mb-2">
                <div class="col-sm-12 text-end">
                  <span v-if="imageLoaded === true">
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" @click="executeProcess($event)">{{$t("lang.label.download")}}</button>
                  </span>
                  <span v-else>
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" disabled>{{$t("lang.label.download")}}</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  //import $ from 'jquery';
  export default {
    metaInfo : {
      title: `${window.application.title} ${window.application.lang.application.image_converter.name}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.application.image_converter.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en/imageconverter'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es/imageconverter'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi/imageconverter'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja/imageconverter'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn/imageconverter'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw/imageconverter'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        lang: window.application.lang,
        imageSettingEffects: window.application.converter.settings.image.effects,
        outputImageTypes: window.application.converter.outputs.image,
        fileName: '',
        imageLoaded: false,
        defaultQuality: 0.8,
        createForm: {
          errors: [],
          type: 'png',
          quality: 0.8,
          image_size: '',
        },
        process: {
          loaded: false,
          completed: false,
          message: null,
          length: 0,
          data:{
            text: ""
          }
        },
        ffmpeg: null,
        file: null,
        canvas: null,
        readerResult: null,
        fileData: null
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      let self = this;
      self.file = document.getElementById('inputMediaFile');
      self.file.addEventListener('change', self.loadLocalImage, false);
      self.setDefaultConfigure();
    },
    updated() {
      //let self = this;
    },
    methods: {
      setDefaultConfigure(){
        let self = this;
        self.setDefaultQuality();
      },
      setDefaultQuality(){
        let self = this;
        self.createForm.quality = self.defaultQuality;
      },
      baseName(str) {
        str = String(str);
        let base = new String(str).substring(str.lastIndexOf('/') + 1); 
          if(base.lastIndexOf(".") != -1)       
              base = base.substring(0, base.lastIndexOf("."));
        return base;
      },
      executeProcess(event) {
        let self = this
        self.createForm.errors = [];
        let obj = window.button.loading(event);
        window.display.loading(this.process);
        self.download(self.createForm.type);
        window.display.reset(this.process);
        window.button.reset(obj);
      },
      loadLocalImage(event) {

        let self = this
        self.canvas = document.getElementById('editMediaFile');

        // Canvasの準備
        let context = self.canvas.getContext('2d');
        let defaultImage = document.getElementById('defaultMediaFile');
        let previewImage = document.getElementById('previewMediaFile');

        // ファイル情報を取得
        if (typeof event.target.files[0] === "undefined") {
          return;
        }
        
        let fileData = event.target.files[0];
        self.fileName = fileData.name;
        //console.log(fileData);

        // 画像ファイル以外は処理を止める
        if(!fileData.type.match('image.*')) {
          self.createForm.errors.push(self.lang.validation.image.replace(':attribute', self.lang.label.source_file));
          window.location.element("#create-form-errors");
          return;
        }

        // FileReaderオブジェクトを使ってファイル読み込み
        let reader = new FileReader();

        // ファイル読み込みに成功したときの処理
        reader.onload = function() {

          //初期化
          defaultImage.src = "";
          previewImage.src = "";
          context.clearRect(0, 0, self.canvas.width, self.canvas.height);
          
          // Canvas上に表示する
          let readerResult = reader.result;

          // Canvas上に画像を表示
          defaultImage.src = readerResult;

          defaultImage.onload = function() {
            self.canvas.width = this.width;
            self.canvas.height = this.height;
            //context.drawImage(defaultImage, 0, 0, canvasWidth, this.height * (canvasWidth / this.width));
            context.drawImage(defaultImage, 0, 0);
            
            previewImage.style.display = "none";
            previewImage.src = this.src;
            previewImage.style.display = "block";

            self.imageLoaded = true;
          }
        }
        // ファイル読み込みを実行
        reader.readAsDataURL(fileData);
      },
      default(src, dst) {
        for (let i = 0; i < src.data.length; i = i + 4) {
            let x = (src.data[i] + src.data[i + 1] + src.data[i + 2]) / 3
            dst.data[i] = dst.data[i + 1] = dst.data[i + 2] = x
            dst.data[i + 3] = src.data[i + 3]
        }
      },
      grayscale(src, dst) {
        for (let i = 0; i < src.data.length; i = i + 4) {
            let x = (src.data[i] + src.data[i + 1] + src.data[i + 2]) / 3
            dst.data[i] = dst.data[i + 1] = dst.data[i + 2] = x
            dst.data[i + 3] = src.data[i + 3]
        }
      },
      threshold(src, dst, int_thresh) {
          int_thresh = int_thresh || 120
          for (let i = 0; i < src.data.length; i = i + 4) {
              let x = (src.data[i] + src.data[i + 1] + src.data[i + 2]) / 3
              if (int_thresh > x) {
                  x = 255
              } else {
                  x = 0
              }
              dst.data[i] = dst.data[i + 1] = dst.data[i + 2] = x
              dst.data[i + 3] = src.data[i + 3]
          }
      },
      invert(src, dst) {
          for (let i = 0; i < src.data.length; i = i + 4) {
              dst.data[i] = Math.abs(255 - src.data[i])
              dst.data[i + 1] = Math.abs(255 - src.data[i + 1])
              dst.data[i + 2] = Math.abs(255 - src.data[i + 2])
              dst.data[i + 3] = src.data[i + 3]
          }
      },
      gamma(src, dst, int_g) {
          int_g = int_g || 2.0
          const correctify = val => 255 * Math.pow(val / 255, 1 / int_g)
          for (let i = 0; i < src.data.length; i += 4) {
              dst.data[i] = correctify(src.data[i])
              dst.data[i + 1] = correctify(src.data[i + 1])
              dst.data[i + 2] = correctify(src.data[i + 2])
              dst.data[i + 3] = src.data[i + 3]
          }
      },

      addEffect(effect) {
        let self = this;
        
        self.canvas = document.getElementById('editMediaFile');
        let context = self.canvas.getContext('2d')
        let defaultImage = document.getElementById('defaultMediaFile');
        let previewImage = document.getElementById('previewMediaFile');

        let src = context.getImageData(0, 0, self.canvas.width, self.canvas.height)
        let dst = context.createImageData(self.canvas.width, self.canvas.height)
        
        if (effect == 'default') {
          context.clearRect(0, 0, self.canvas.width, self.canvas.height);
          let readerResult = defaultImage.src;
          defaultImage.src = readerResult;
          defaultImage.onload = function() {
            //context.drawImage(defaultImage, 0, 0, self.canvas.width, this.height * (self.canvas.width / this.width));
            context.drawImage(defaultImage, 0, 0);
            previewImage.style.display = "none";
            previewImage.src = this.src;
            previewImage.style.display = "block";
          }
        } else {
          if (effect == 'grayscale') {
            self.grayscale(src, dst);
          }
          if (effect == 'threshold') {
            self.threshold(src, dst);
          }
          if (effect == 'invert') {
            self.invert(src, dst);
          }
          if (effect == 'gamma') {
            self.gamma(src, dst);
          }
          context.putImageData(dst, 0, 0)
          previewImage.style.display = "none";
          previewImage.src = self.canvas.toDataURL();
          previewImage.style.display = "block";
        }
      },

      download(extension) {
        let self = this;
        let data = null;
        let canvas = null;

        canvas = document.getElementById('editMediaFile');
        if (self.outputImageTypes[self.createForm.type].use_quality === true) {
          //data = canvas.toDataURL(self.outputImageTypes[self.createForm.type].mime, self.createForm.quality);
          data = canvas.toDataURL(self.outputImageTypes[self.createForm.type].mime);
        } else {
          data = canvas.toDataURL(self.outputImageTypes[self.createForm.type].mime);
        }

        let baseName = self.baseName(self.fileName);
        let downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = baseName + "." + extension;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
    }
  }
</script>

<style>

  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {

  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
