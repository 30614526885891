<template>
  <div>
    <div class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
      <div class="container">
        <a :href="`/${locale}`" class="navbar-brand">
          <img src="/images/icon.png" height="24">&nbsp;
          <strong>{{title}}</strong>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" id="navbarDropdownMultimedia">{{$t("lang.class.multimedia.name")}} <span class="caret"></span></a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMultimedia">
                <li><a class="dropdown-item" :href="`/${locale}/videoconverter`">{{$t("lang.application.video_converter.name")}}</a></li>
                <li><a class="dropdown-item" :href="`/${locale}/audioconverter`">{{$t("lang.application.audio_converter.name")}}</a></li>
                <li><a class="dropdown-item" :href="`/${locale}/imageconverter`">{{$t("lang.application.image_converter.name")}}</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" id="navbarDropdownText">{{$t("lang.class.text.name")}} <span class="caret"></span></a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownText">
                <!--<li><a class="dropdown-item" :href="`/${locale}/encodetools`">{{$t("lang.application.encode_tools.name")}}</a></li>-->
                <li><a class="dropdown-item" :href="`/${locale}/jsontools`">{{$t("lang.application.json_tools.name")}}</a></li>
                <li><a class="dropdown-item" :href="`/${locale}/xmltools`">{{$t("lang.application.xml_tools.name")}}</a></li>
                <li><a class="dropdown-item" :href="`/${locale}/yamltools`">{{$t("lang.application.yaml_tools.name")}}</a></li>
                <li><a class="dropdown-item" :href="`/${locale}/tomltools`">{{$t("lang.application.toml_tools.name")}}</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" id="navbarDropdownOthers">{{$t("lang.class.others.name")}} <span class="caret"></span></a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownOthers">
                <li><a class="dropdown-item" :href="`/${locale}/regextester`">{{$t("lang.application.regex_tester.name")}}</a></li>
                <li><a class="dropdown-item" :href="`/${locale}/uuidgenerator`">{{$t("lang.application.uuid_generator.name")}}</a></li>
              </ul>
            </li>
          </ul>
          <ul class="navbar-nav ms-md-auto">
            <li class="nav-item">
              <a class="nav-link" :href="`/${locale}/privacypolicy`">{{$t("lang.application.privacy_policy.name")}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="`mailto:${email}`">{{$t("lang.application.contact.name")}}</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" id="navbarDropdownLanguage">{{$t("lang.class.language.name")}} <span class="caret"></span></a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownLanguage">
                <li v-for="(name, key) in locales" :key="key">
                  <a class="dropdown-item" :href="`/${key}/${uri}`" v-if="uri != ''">{{name}}</a>
                  <a class="dropdown-item" :href="`/${key}`" v-else>{{name}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid"><component-advertisement-top></component-advertisement-top></div>
  </div>
</template>

<script>
  import Vue from 'vue';
  //import $ from 'jquery';
  export default {
    data () {
      return {
        title: window.application.title,
        domain: window.application.domain,
        email: window.application.email,
        locales: window.application.locales,
        locale: window.application.locale,
        pathname: window.location.pathname,
        uri: '',
      }
    },
    created() {
      Vue.component('component-advertisement-top', require('../components/AdvertisementTop.vue').default);
    },
    mounted() {
      let self = this;
      let re = new RegExp("^\\/" + self.locale);
      let tempUri = self.pathname.replace(re, '');
      re = new RegExp("^\\/");
      tempUri = tempUri.replace(re, '');
      re = new RegExp("/$");
      self.uri = tempUri.replace(re, '');
    },
    methods: {

    }
  }
</script>

<style>

body {
  padding-top: 6rem !important;
  /*
  padding-top: 82px !important;
  padding-bottom: 82px !important;
  */
}
</style>
