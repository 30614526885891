<template>
  <div class="row">
    <div class="col-lg-12 mb-2">
      <div style="margin:0 auto;text-align:center;">
        <component-advertisement></component-advertisement>
      </div>
    </div>
  </div>
</template>

<script>
  //import $ from 'jquery';
  //import Vue from 'vue';
  export default {
    data () {
      return {
        title: window.application.title,
        domain: window.application.domain,
        email: window.application.email,
        locales: window.application.locales,
        locale: window.application.locale,
        pathname: window.location.pathname,
        uri: '',
      }
    },
    created() {
      //let self = this;
      //if (self.locale == 'ja') {
      //Vue.component('component-advertisement', require('../advertisements/_Blank.vue').default);
      //Vue.component('component-advertisement', require('../advertisements/NinjaAdmaxBottom.vue').default);
      //Vue.component('component-advertisement', require('../advertisements/AdsterraBottom.vue').default);
      //}
    },
    mounted() {
      let self = this;
      let re = new RegExp("^\\/" + self.locale);
      let tempUri = self.pathname.replace(re, '');
      re = new RegExp("^\\/");
      tempUri = tempUri.replace(re, '');
      re = new RegExp("/$");
      self.uri = tempUri.replace(re, '');
    },
    methods: {

    }
  }
</script>

<style>

body {
  padding-top: 6rem !important;
  /*
  padding-top: 82px !important;
  padding-bottom: 82px !important;
  */
}
</style>
