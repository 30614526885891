<template>
  <div class="footer mt-4 py-3 content">
    <div class="container-fluid"><component-advertisement-bottom></component-advertisement-bottom></div>
    <div>{{copyRight}}</div>
  </div>
</template>

<script>
  import Vue from 'vue';
  //import $ from 'jquery';
  export default {
    data () {
      return {
        title: window.application.title,
        domain: window.application.domain,
        email: window.application.email,
        copyRight: window.application.copy_right,
        locales: window.application.locales,
        locale: window.application.locale,
        pathname: window.location.pathname,
        uri: '',
      }
    },
    created() {
      Vue.component('component-advertisement-bottom', require('../components/AdvertisementBottom.vue').default);
    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style>

.content {
  font-size: 12px;
  text-align: center;
}

</style>
